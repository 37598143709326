export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  BookOnBehalf: 'specs.bookings.BookOnBehalf',
  CancellationFeesUoU: 'specs.bookings.CancellationFeesUoU',
  ShowNewBookingPolicySection: 'specs.bookings.ShowNewBookingPolicySection',
  ShowDepositRequiredOnBookingPolicySection:
    'specs.bookings.ShowDepositRequiredOnBookingPolicySection',
};
